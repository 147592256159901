.intro,
.intro-bg {
  @include transition(all ease 1s);
}

.postal-card {
  background-color: transparent !important;

  .intro-bg {
    opacity: 1 !important;
  }

  .select-city {
    opacity: 0;
  }
}

.select-city {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.intro {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  color: rgba(white, .9);
  background-color: rgba($brand-primary, .9);
  position: relative;

  .redirect {
    position: absolute;
    text-align: center;
    bottom: 0;
    margin-bottom: 1rem;
  }

  .logo {
    position: absolute;
    top: 0;
    left: 0;
    margin: 1rem;
  }

  .intro-bg {
    background-image: url('/img/intro-bg.jpg');
    background-size: cover;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    opacity: 0.4;
  }

  .highlight {
    color: white;
  }

  .branches {
    list-style: none;

    li {
      float: left;
    }

    a {
      padding: .5rem 1rem;
      color: rgba(white, 0.5);
      font-size: 1.5rem;
      font-weight: 300;
      display: inline-block;
      @include transition(all ease .2s);

      &:hover {
        color: white;
        text-decoration: none;
      }
    }
  }
}