$custom-modal-hover-color: rgba(white, .5);
$custom-modal-text-color: rgba(white, .75);

section {
  position: relative;
}

.bg-brand-primary {
  background-color: $brand-primary;
}

.bg-brand-secondary {
  background-color: $brand-secondary;
}

.text-brand-primary {
  color: $brand-primary;
}

.text-brand-primary-light {
  color: rgba($brand-primary, .75);
}

.text-brand-secondary {
  color: $brand-secondary;
}

.text-brand-secondary-light {
  color: rgba($brand-secondary, .75);
}

.bb-brand-secondary {
  border-bottom: 1px solid $brand-secondary;
}

// remove caret do dropdown
.dropdown-toggle::after {
  display: none;
}

// floating label
// floating label
.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group>input,
.form-label-group>label,
.form-group>input,
.form-group>select {
  height: 3.125rem;
  padding: .75rem;
}

.form-label-group>label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #777;
  pointer-events: none;
  cursor: text;
  /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: .25rem;
}

.form-label-group input:not(:placeholder-shown)~label {
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 12px;
  color: #777;
}


/* Fallback for Edge
-------------------------------------------------- */

@supports (-ms-ime-align: auto) {
  .form-label-group>label {
    display: none;
  }

  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .form-label-group>label {
    display: none;
  }

  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}

// custom modal
.custom-modal {
  visibility: hidden;
  opacity: 0;
  color: $custom-modal-text-color;
  background-color: $brand-primary;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  overflow-y: auto;
  @include transition(all ease-in .3s);

  .custom-modal-content {
    height: 100%;
    position: relative;
  }

  .custom-modal-title {
    font-size: 18pt;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;

    @include media-breakpoint-down(sm) {
      margin-top: 2rem;
    }
  }

  .custom-modal-close {
    margin-left: auto;
    margin-right: 2rem;
    color: $custom-modal-hover-color;
    font-size: 36pt;
    font-weight: 100;
    position: absolute;
    top: 0;
    right: 0;
    @include transition(all ease-in .15s);

    &::after {
      content: 'x';
    }

    &:hover {
      cursor: pointer;
      color: $custom-modal-text-color;
    }
  }

  .custom-modal-header {
    align-items: center;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    border-bottom: 1px solid rgba(darken($brand-primary, 50%), .5);
  }

  .custom-modal-body {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    color: rgba(white, .6);
    font-size: 20pt;
    font-weight: 100;
    text-align: center;

    @include media-breakpoint-up(sm) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }

  &.show {
    visibility: visible;
    opacity: 1;
    z-index: $zindex-fixed + 1;
  }

  .spinner-border {
    color: $custom-modal-text-color;
  }
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(white, .5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
  @include transition(opacity .5s ease-in);

  &.show {
    opacity: 1;
    z-index: 1;
  }
}