$color: rgba(white, .75);

#idealPlanModal {
  background-color: $brand-primary;
  color: $color;

  .plan {
    padding: 1.5rem 2rem;
    border-radius: 5px;
    border: 1px solid $color;
    color: $color;
  
    .title {
      font-size: 56pt;
    }
  
    .subtitle {
      font-size: 36pt;
      text-transform: uppercase;
    }
  }

  .modal-dialog {
    margin: 0 auto;
    max-width: 100% !important;
  }

  .modal-content {
    width: 100% !important;
    background-color: transparent;
    border: none;
  }

  .modal-header {
    padding: 1.5rem;
  }

  .modal-title i {
    margin-right: .5rem;
    @include scale(1.3);
  }

  .btn-outline-white {
    color: $color;
    border-color: $color;

    &:hover {
      color: $brand-primary;
      background-color: $color;
      border-color: $color;
    }
  }

  .steps {
    @include media-breakpoint-up(sm) {
      width: 90%;
    }

    @include media-breakpoint-up(md) {
      width: 80%;
    }

    @include media-breakpoint-up(lg) {
      width: 70%;
    }

    @include media-breakpoint-up(xl) {
      width: 60%;
    }
  }

  .custom-checkbox,
  .custom-radio {
    display: inline-block;
    position: relative;
    padding-left: 100px;
    padding-bottom: 100px;
    cursor: pointer;
    font-size: 24pt;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.custom-checkbox-lg,
    &.custom-radio-lg {
      padding-left: 150px;
      padding-bottom: 150px;

      .checkmark {
        height: 150px;
        width: 150px;
      }
    }

    &:not(:first-child) {
      margin-left: 1rem;
    }

    &:hover input~.checkmark {
      background-color: rgba(white, .25);
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked~.checkmark {
        background-color: rgba(white, .5);
      }
    }

    /* Create a custom checkbox */
    .checkmark {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 28pt;
      position: absolute;
      top: 0;
      left: 0;
      height: 100px;
      width: 100px;
      background-color: transparent;
      border: 1px solid $custom-modal-text-color;
      border-radius: 5px;

      .info {
        padding-top: .5rem;
        font-size: 14pt;
      }
    }
  }

  .validation-error {
    color: red;
    opacity: 0;
    @include transition(opacity .5s ease-in);

    &.show {
      opacity: 1;
    }
  }

}