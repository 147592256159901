.steps {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  width: 100%;
  text-align: center;
  position: relative;

  .step {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3rem 0;
    width: 100%;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    @include transition(opacity .5s ease-in);

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
}