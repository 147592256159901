@each $color,
$value in $brand-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color,
$value in $brand-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

@each $color,
$value in $social-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color,
$value in $social-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

.btn-white {
  @include button-variant(white, white);
}

.btn-outline-white {
  @include button-outline-variant(white);
}

.btn-rounded {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  border-radius: 50px !important;
}

.btn-circle {
  padding: 6px 16px;
  border-radius: 100%;
}