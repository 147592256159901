#banner {
  min-height: 300px;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 10%;
  }

  .carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 100%;
  }
}