.top-nav,
.top-nav .nav-link,
.nav-link,
.nav-social *,
.main-nav,
.main-nav .logo,
.main-nav .navbar {
  @include transition(all ease .3s);
}

.top-nav {
  padding-top: .75rem;
  padding-bottom: .75rem;
  background-color: #fff;
  border-bottom: 1px solid $brand-primary;

  @include media-breakpoint-down(xs) {
    padding-top: 0;
    padding-bottom: 0;
  }

  .nav-link {
    display: flex;
    justify-content: center;
    color: #444;
    padding: .5rem;

    &:hover {
      color: #111;

      i {
        color: $brand-secondary;
      }
    }

    i {
      color: $brand-primary;
      font-size: 1.5rem;
    }
  }
}

.fixed-top {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  @include box-shadow(0px 2px 6px 2px rgba(black, .1));

  .logo {
    height: 56px !important;
  }
}

.nav-social {

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $brand-primary;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    color: white;
    font-size: 1rem;
    float: left;

    &:not(:first-child) {
      margin-left: .25rem;
    }

    &:hover {
      text-decoration: none;
      background-color: $brand-secondary;

      i {
        @include scale(1.3);
      }
    }
  }
}

.main-nav {

  .logo {
    height: 70px;
  }

  .logo-single {
    height: 56px;
    display: none;
  }

  .navbar-toggler {
    margin-top: 1rem;
    display: none !important;

    @include media-breakpoint-down(md) {
      display: block !important;
    }
  }

  .navbar-brand {
    @include media-breakpoint-down(md) {
      margin-left: auto;
      margin-right: auto;
    }

    @include media-breakpoint-down(xs) {
      margin-left: 0;
      margin-right: auto;

      .logo {
        display: none;
      }

      .logo-single {
        display: block;
      }
    }
  }

  .navbar {
    padding-top: 1rem;
    padding-bottom: 1rem;

    @include media-breakpoint-down(xs) {
      padding-top: 0;
      padding-bottom: 0;
    }

    .btn {
      margin-left: 1rem;
      @include media-breakpoint-down(md) {
        display: block;
        margin-left: 0;
        margin-bottom: 1rem;
      }
    }

    .nav-link {
      color: $brand-primary;
      font-size: 1.25rem;
      font-weight: 300;

      &::after {
        content: '';
        display: block;
        width: 0;
        height: 2px;
        background: $brand-secondary;
        transition: width .3s;
      }

      &:hover {
        color: darken($brand-primary, 10%);
        // @include scale(1.1);

        &::after {
          width: 100%;
        }
      }
    }
  }
}

.btn-topmenu {
  display: none;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}