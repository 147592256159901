.plan-info {
  display: inline;
  padding-right: 1rem;
  padding-bottom: .125rem;
  margin-left: 1rem;
  border-bottom: 1px solid $brand-secondary;
  color: $brand-primary;
  font-size: 16pt;
  text-transform: uppercase;
}

.plan {
  margin-top: .5rem;
  padding: 1rem;

  .plan-content {
    @include box-shadow(0px 2px 6px 2px rgba(black, .1));
  }

  .plan-title {
    font-size: 80pt;
    font-weight: 700;
  }

  .plan-subtitle {
    font-size: 42pt;
    text-transform: uppercase;
  }

  .plan-group {
    padding-top: .5rem;
    padding-bottom: .5rem;
    text-transform: uppercase;

    i {
      font-size: 14pt;
    }
  }

  .plan-header {
    background-color: $brand-secondary;
    color: white;
    text-align: center;
  }

  .plan-price {
    display: flex;
    justify-content: center;
    align-items: center;

    .symbol {
      font-size: 24pt;
    }

    .price {
      display: flex;
      justify-content: start;

      .int {
        font-size: 56pt;
      }

      .unit {
        padding-top: 1rem;
        font-size: 24pt;
      }
    }
  }

  .plan-body {}

}

#planoIdeal {

  .ideal-plan {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    margin: 1.5rem auto;
    width: 250px;
    height: 250px;
    border: 1px solid $custom-modal-text-color;
    border-radius: 100%;
    font-size: 42pt;

    .info {
      font-size: 24pt;
      display: block;
    }
  }

  .btn-outline-white {
    color: rgba(white, .5);
    border-color: rgba(white, .5);

    &:hover {
      color: $brand-primary;
      background-color: rgba(white, .5);
      border-color: rgba(white, .5);
    }
  }

  .custom-checkbox,
  .custom-radio {
    display: inline-block;
    position: relative;
    padding-left: 100px;
    padding-bottom: 100px;
    cursor: pointer;
    font-size: 24pt;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.custom-checkbox-lg,
    &.custom-radio-lg {
      padding-left: 150px;
      padding-bottom: 150px;

      .checkmark {
        height: 150px;
        width: 150px;
      }
    }

    &:not(:first-child) {
      margin-left: 1rem;
    }

    &:hover input~.checkmark {
      background-color: rgba(white, .25);
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked~.checkmark {
        background-color: rgba(white, .5);
      }
    }

    /* Create a custom checkbox */
    .checkmark {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 28pt;
      position: absolute;
      top: 0;
      left: 0;
      height: 100px;
      width: 100px;
      background-color: transparent;
      border: 1px solid $custom-modal-text-color;
      border-radius: 5px;

      .info {
        padding-top: .5rem;
        font-size: 14pt;
      }
    }
  }

  .step {
    width: 100%;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: 50%;
    @include translateX(-50%);
    @include transition (all ease .15s);

    &.active {
      visibility: visible;
      opacity: 1;
    }
  }

}