.footer-bar,
.footer-bar .btn-to-top,
.speedtester-item {
  @include transition(all ease .5s);
}

.speedtester-item {
  img {
    height: 60px;
  }

  &:hover {
    @include scale(1.2);
  }
}

.footer-bar {
  opacity: 0;
  background-color: #111;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  @include media-breakpoint-down(md) {
    display: none;
  }

  &.active {
    opacity: 1;
    margin-bottom: 0;
  }

  .footer-bar-content {
    position: relative;
  }

  .btn-to-top {
    display: table;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    color: #eee;
    text-align: center;
    text-decoration: none;

    &:hover {
      color: #fff;
      @include scale(1.2);
    }

    i {
      font-size: 1.5rem;
    }

    span {
      display: block;
    }

    @include media-breakpoint-up(md) {
      margin-right: 3rem;
      margin-top: 2rem;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .row {
    @include media-breakpoint-down(sm) {
      margin-top: 4rem !important;

      [class^="col-"]:first-child,
      [class*=" col-"]:first-child {
        margin-bottom: 1rem !important;
      }
    }
  }
}

footer {
  color: $brand-primary;
  background: white;

  /*
  @include media-breakpoint-down(md) {
    margin-bottom: 130px;
  }

  &.without-bar {
    margin-bottom: 0 !important;
  }
  */

  .menu {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .address {
    @include media-breakpoint-down(md) {
      text-align: center;
      margin-bottom: 1rem;

      i {
        display: none;
      }
    }
  }

  .facebook {
    display: flex;

    @include media-breakpoint-down(lg) {
      .fb-page {
        width: 300px;
      }
    }

    @include media-breakpoint-down(md) {
      justify-content: center;
    }
  }

  .nav-link {
    padding: .25rem 0;
  }
}