// Body bg
$body-bg: #f1f1f1;

// Typography
$font-family-sans-serif: "Nunito", sans-serif;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66D9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #fbce74;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// Brand coloer
$brand-primary: #004c75;
$brand-secondary: #572e9c;

// Brand map colors
$brand-colors: () !default;
$brand-colors: map-merge((
  "brand-primary": $brand-primary,
  "brand-secondary": $brand-secondary
), $brand-colors);

// Social colors
$facebook-color: #3b5998;
$twitter-color: #1da1f2;
$google-color: #ea4335;

// Social map colors
$social-colors: () !default;
$social-colors: map-merge((
  "facebook": $facebook-color,
  "twitter": $twitter-color,
  "google": $google-color
), $social-colors);

// Link
$link-color: $brand-primary;
$link-hover-color: darken($link-color, 15%);

// Navbar light
$navbar-light-color: $brand-secondary;
$navbar-light-hover-color: darken($navbar-light-color, 10%);
$navbar-light-active-color: darken($navbar-light-color, 15%);

// input
//$input-bg: #f9f9f9;
//$input-focus-bg: white;
//$input-border-color: #eee;
$input-focus-border-color: lighten($brand-secondary, 25%);
$input-btn-focus-color: rgba($brand-secondary, .25);
$input-btn-focus-box-shadow: none;
//$input-focus-box-shadow: none;
